export const projectsList = [
  {
    projectTitle: "Personal Website",
    projectDescription:
      "My personal website that includes my work experience, personal projects I've worked on and ways to contact me. You're on here now!",

    skills: ["JavaScript", "React", "styled-components", "Framer Motion"],
  },
  {
    projectTitle: "Job Parser",
    projectDescription:
      "A web application that scrapes job postings from various websites and displays them in a clean and organized manner. It leverages the power of ChatGPT to summarize job descriptions and provide a standardized data format, even for job postings that do not follow any standard. (Not yet deployed)",
    externalLink: "",
    skills: [
      "JavaScript",
      "React",
      "TypeScript",
      "Node.js",
      "ChatGPT",
      "PostgreSQL",
    ],
  },
  {
    projectTitle: "Treasure Box Braille",
    projectDescription:
      "A Braille learning tool for visually impaired children powered by Raspberry Pi, created as part of the Lassonde Undergraduate Research Award. The device is DIY with off-the-shelf parts, and the software allows the user to create their own engaging stories with Braille characters. Presented the project at the LURA conference at Lassonde School of Engineering.",
    externalLink: "https://github.com/sunnylee0123/TBB",
    skills: ["Java", "C", "Linux/Unix", "Bash", "Raspberry Pi", "Git"],
  },
  {
    projectTitle: "Volunteer Tracker, 'When I Becomes We'",
    projectDescription:
      "Contributing to creating a volunteer tracker for the non-profit organization 'When I Becomes We', which aims to connect volunteers looking for opportunities with other non-profit organizations.",
    externalLink: "",
    skills: ["JavaScript", "TypeScript", "Tailwind", "Next.js", "GCP"],
  },
  // {
  //   projectTitle: "Haptic-feedback Interface Robot",
  //   projectDescription:
  //     "A haptic interface robot with linear motors, position sensors, power electronics and a PID Control system all built from scratch with a GUI to display the real-time position of the motors and utilize the haptic feedback to simulate collision with objects and materials using custom built software.",
  //   skills: [
  //     "C",
  //     "PCB Design",
  //     "Power Electronics",
  //     "PID Controls",
  //     "3D Printing / Rapid Prototyping",
  //   ],
  // },
];
