export const contactMe = [
  {
    link: "mailto:sunnylee0123@gmail.com",
    imgSrc: "company-logos/mail-svgrepo-com.svg",
    height: 50,
    alt: "mail link",
  },
  {
    link: "https://www.linkedin.com/in/sunjik-lee/",
    imgSrc: "company-logos/linkedin-svgrepo-com.svg",
    height: 50,
    alt: "linkedin link",
  },
  // {
  //   link: "https://www.instagram.com/sunnylee0123/",
  //   imgSrc: "company-logos/instagram-svgrepo-com.svg",
  //   height: 50,
  //   alt: "instagram link",
  // },
  {
    link: "https://github.com/sunnylee0123",
    imgSrc: "company-logos/github-142-svgrepo-com.svg",
    height: 45,
    alt: "github link",
  },
];
