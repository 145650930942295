export const workExperiences = [
  {
    positionTitle: "Full Stack Developer",
    companyName: "TELUS",
    companyLink: "https://www.telus.com/en",
    companyLogoPath: "company-logos/TELUS.png",
    experience: [
      "Full stack developer in Enterprise Systems team responsible for designing and building applications and REST APIs.",
      "Implemented new features and handled support tickets for a company-wide URL shortcut management application to enable team members to access company resources efficiently and effectively.",
      "Built new enterprise application throughout the full software development lifecycle while working closely with stakeholders to ensure expectations and requirements are met.",
      "Revamped existing CI/CD pipelines using GitHub Actions, enabling quick testing and deployment through Git.",
      "Modernized legacy enterprise applications to bring them up to date with security and compatibility standards, allowing hundreds of users to continue using them without disruptions.",
    ],
    skills: [
      "JavaScript",
      "HTML",
      "CSS",
      "React",
      "Node.js",
      "PostgreSQL",
      "Git",
      "GCP",
      "Java",
      "PHP",
    ],
    startDate: "Oct 2021",
    endDate: "Sep 2023",
  },
  {
    positionTitle:
      "Technology Specialist, Graduate Technology Leadership Program",
    companyName: "TELUS",
    companyLink: "https://www.telus.com/en",
    companyLogoPath: "company-logos/TELUS.png",
    experience: [
      "Built REST APIs that are used by external companies to enhance their features using TELUS data and services.",
      "Integrated both internal and external APIs into existing applications to enable new features and improve existing ones.",
      "Implemented new access control features for an internal tool to TELUS developers to manage the access to their team’s APIs, saving developers time and reducing manual intervention.",
      "Wrote testing suite for existing applications, ensuring that new code does not impact existing functionality.",
      "Created a React frontend app for the security team to run and manage their Ansible playbooks using AWX APIs, enabling less technical team members to view critical outputs in a more user-friendly manner. ",
      "Led a team of developers to revamp an internal employee recognition form into a full-stack application on Google Cloud, while engaging management and stakeholders to ensure alignment on features and timelines.",
    ],
    skills: [
      "React",
      "JavaScript",
      "TypeScript",
      "Node.js",
      "Express",
      "Ansible",
      "GCP",
      "Git",
      "Figma",
      "OpenShift",
    ],
    startDate: "Sep 2019",
    endDate: "Sep 2021",
  },
  {
    positionTitle: "Software Programmer Internship - Web Development",
    companyName: "CEO Law Technology Corporation",
    companyLink: "https://www.ceolawcanada.com",
    companyLogoPath: "company-logos/CEOLaw.png",
    experience: [
      "Worked with a team of senior developers to develop new features for the company website while learning best practices for software development.",
      "Developed new pages for the company website, working on both the frontend and backend code.",
      "Used RSpec for testing new and existing features as well as fixing bugs to enhance user experience.",
    ],
    skills: ["HTML", "CSS", "JavaScript", "Ruby On Rails", "PostgreSQL", "Git"],
    startDate: "May 2018",
    endDate: "Aug 2018",
  },
  {
    positionTitle: "Software Developer Research Assistant",
    companyName: "York University, Practices in Enabling Technology (PiET) Lab",
    companyLink: "https://piet.apps01.yorku.ca/",
    companyLogoPath: "company-logos/YorkULogo.png",
    experience: [
      "Developed braille teaching software for visually impaired children, with the goal of creating an open-source, low-cost, and accessible braille educational product.",
      "Worked with undergraduate and graduate students to brainstorm, design, and create a high-fidelity prototype, resulting in a product that is several times less expensive than the leading commercial product.",
      "Presented and demonstrated the prototype at Lassonde Undergraduate Summer Student Research Conference, creating more awareness of the project within the Lassonde community.",
    ],
    skills: ["Java", "C", "Linux/Unix", "Bash", "Raspberry Pi", "Git"],
    startDate: "May 2017",
    endDate: "Aug 2017",
  },
];
