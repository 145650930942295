import React from "react";
import { motion } from "framer-motion";
import { Section } from "./styles";
import { Animator } from "../../components/helpers/animationHelper";
import Button from "../../components/Button";

function AboutMe() {
  return (
    <Section id={"about me"}>
      <motion.div {...new Animator().withDelay(1.5).getProps()}>
        <span>
          <img src={"SunjikLee.png"} height={150} alt={"picture of me"} />
        </span>
      </motion.div>
      <motion.p
        {...new Animator().withDelay(0.2).withDuration(1).getProps()}
        className="Intro"
      >
        Hi, my name is
      </motion.p>
      <motion.h1
        {...new Animator().withDelay(0.2).withDuration(1).getProps()}
        className="Header"
      >
        SUNJIK LEE
      </motion.h1>
      <motion.p
        {...new Animator().withDelay(1.5).getProps()}
        className="AboutMeBlurb"
      >
        I'm a <b>software developer</b> with a strong focus on creating
        user-friendly applications that solve real-world problems. I'm
        experienced in frontend and backend development throughout the software
        development life cycle. With experience using multiple cloud providers,
        I'm well-equipped to tackle any project, and I'm committed to delivering
        high-quality software that delights users. I'm eager to continue
        learning and growing as a software developer.
      </motion.p>
      <motion.div
        {...new Animator().withDelay(1.5).getProps()}
        className="Buttons"
      >
        <Button
          href={process.env.PUBLIC_URL + "/Sunjik_Lee_Resume.pdf"}
          download={"Sunjik_Lee_Resume.pdf"}
        >
          Download Resume
        </Button>
        <Button href={"mailto:sunnylee0123@gmail.com"}>Get In Touch</Button>
      </motion.div>
    </Section>
  );
}

export default AboutMe;
